import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { PubNubAngular } from 'pubnub-angular2';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'ion7-calendar';
import { Calendar } from '@ionic-native/calendar/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FilterModalComponent } from '../components/filter-modal/filter-modal';
import { RegisterForTaskComponent } from '../components/register-for-task/register-for-task.component';
import { MarkTaskDoneComponent } from '../components/mark-task-done/mark-task-done.component';

import { TaskService } from '../providers/task-service';
import { ToastService } from '../providers/toast-service';
import { RegisterForTaskService } from '../providers/register-for-task-service';
import { MarkTaskDoneService } from '../providers/mark-task-done-service';
import { FilterService } from '../providers/filter-service';
import { NavDataService } from '../providers/nav-data-service';
import { DateHelperService } from '../providers/date-helper-service';
import { OpenTasksService } from '../providers/open-tasks-service';
import { AuthService } from '../providers/auth-service';
import { UserDataService } from '../providers/user-data-service';
import { AuthInterceptor } from '../providers/auth-interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MainPipeModule } from '../pipes/main-pipe.module';
import { FormsModule } from '@angular/forms';
import { OwnTasksService } from '../providers/own-tasks-service';
import { ChatService } from '../providers/chat-service';
import { ChangePasswordService } from '../providers/change-password-service';
import { RouterService } from '../providers/router-service';
import { InitializeService } from '../providers/initialize-service';
import { PlatformService } from '../providers/platform-service';
import { CustomerTasksService } from '../providers/customer-tasks-service';
import { CustomerLinksService } from '../providers/customers-links-service';
import { LinkService } from '../providers/link-service';
import { OpentokService } from '../providers/opentok-service';
import { CryptoJsService } from '../providers/crypto-js-service';
import { LoginWithTokenService } from '../providers/login-with-token.service';
import { SetPasswordService } from '../providers/set-password-service';
import { CacheService } from '../providers/cache-service';
import { SettingsService } from '../providers/settings-service';
import { PublicTasksService } from '../providers/public-tasks.service';
import { RegisterPublicTaskService } from '../providers/register-public-task-service';
import { ResetPasswordService } from '../providers/reset-password-service';

function getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macosPlatforms'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

function getMode() {
    const OS = getOS();

    return OS === 'Mac OS' || OS === 'iOS' ? 'ios' : 'md';
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent, FilterModalComponent],
    imports: [
        BrowserModule,
        CommonModule,
        IonicModule.forRoot({ mode: getMode() }),
        AppRoutingModule,
        HttpClientModule,
        MainPipeModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        FormsModule,
        CalendarModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        Calendar,
        AuthService,
        UserDataService,
        HttpClient,
        HttpClientModule,
        PubNubAngular,
        TranslateService,
        OpenTasksService,
        DateHelperService,
        NavDataService,
        FilterService,
        RegisterForTaskComponent,
        RegisterForTaskService,
        MarkTaskDoneComponent,
        MarkTaskDoneService,
        ToastService,
        TaskService,
        LinkService,
        OwnTasksService,
        ChatService,
        ChangePasswordService,
        RouterService,
        InitializeService,
        PlatformService,
        CustomerTasksService,
        CustomerLinksService,
        OpentokService,
        CryptoJsService,
        LoginWithTokenService,
        SetPasswordService,
        CacheService,
        SettingsService,
        PublicTasksService,
        RegisterPublicTaskService,
        ResetPasswordService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useFactory() {
                return new AuthInterceptor();
            },
            multi: true,
            deps: [Router]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
